@import '../abstract/colors';
@import '../abstract/mixins';

.container {
  position: sticky;
  top: 54px; // page header height is 54px
  background: #{$primary-white};
  border-bottom: 2px solid #{$shades-grey-10};
  box-shadow: 0px 6px 14px 1px rgba(76, 82, 95, 0.08);
  padding: 12px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  overflow-x: scroll;
  z-index: 4;
}
.showAAnnouncementBox {
  top: 115px;
}
.container::-webkit-scrollbar {
  display: none;
}
