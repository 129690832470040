.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.tooltipWrapper {
  margin-left: 5.63px;
}

.tooltipAdditionalStyle {
  max-width: 337.79px !important;
}
