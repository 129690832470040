$screen-xs: 570px;
$screen-sm: 640px;
$screen-md: 980px;
$screen-lg: 1025px;

// Typography
$font-openSans: 'OpenSans', sans-serif;
$font-openSans-semiBold: 'OpenSansSemiBold', sans-serif;
$font-openSans-bold: 'OpenSansBold', sans-serif;
$font-kanyon: 'Kanyon', sans-serif;
$font-kanyon-medium: 'KanyonMedium', sans-serif;
$font-kanyon-bold: 'KanyonBold', sans-serif;
