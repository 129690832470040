@import '../abstract/colors';
@import '../abstract/mixins';
@mixin borderColor($type) {
  @if $type==default {
    border: 1px solid #{$shades-grey-10};
  } @else if $type==focus {
    border: 1px solid #{$secondary-blue};
  } @else if $type==error {
    border: 1px solid #{$secondary-brick-red};
  } @else {
    border: 1px solid #{$shades-grey-10};
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.titleText {
  font-size: 12px;
  color: #{$shades-grey-75};
  padding: 0px 0px 8px;
}

.input {
  @include borderColor(default);
  padding: 12px 16px;
  width: 100%;
  font-family: $font-openSans;
  height: 48px;
  font-size: 14px;
  color: #{$primary-black};
  border-radius: 8px;
  outline: none;
  background-color: #{$primary-white};
  @include placeholder {
    color: #{$shades-grey-50};
  }
}

.input:focus {
  @include borderColor(focus);

  &.error {
    @include borderColor(error);
  }
}

.input[disabled] {
  color: #{$primary-black};
}

.error {
  @include borderColor(error);
}

.disabled {
  background-color: #{$shades-grey-10};
  color: #{$shades-grey-75} !important;
}

.errorText {
  font-size: 12px;
  color: #{$secondary-brick-red};
  margin-top: 8px;
}
