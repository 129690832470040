@import '../abstract/colors';
@import '../abstract/mixins';

.container {
  border-radius: 10px;
  background-color: #{$primary-white};
  max-width: 259px;
  display: block;
  color: black;
  overflow: hidden;
  flex-shrink: 0;
  margin-top: 46px;
}

.header {
  display: flex;
  padding: 7px 8px;
  align-items: center;
}

.instagramName {
  margin-left: 8px;
  margin-right: 4px;
  font-size: 13px;
  font-weight: 500;
  font-family: 'OpenSansSemiBold';
}

.image {
  width: 100%;
  display: block;
  object-fit: cover;
  width: 232px;
  height: 232px;
}
