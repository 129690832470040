@import './variable';

@mixin xs {
  @media (min-width: #{$screen-xs}) {
    @content;
  }
}
@mixin sm {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

@mixin mobileXsView {
  @include xs() {
    max-width: 570px;
    margin: 0 auto;
    position: relative;
  }
}

@mixin mobileView {
  @include sm {
    max-width: 570px;
    margin: 0 auto;
    position: relative;
    @content;
  }
}

// typography
@mixin fontFamily($font: $font-openSans, $weight: 400) {
  font-family: $font;
  font-weight: $weight;
}

@mixin h1($font: regular) {
  font-size: 28px;
  line-height: 40px;

  @if $font == bold {
    @include fontFamily($font-kanyon-bold, 700);
  } @else {
    @include fontFamily($font-kanyon, 400);
  }
}

@mixin h2($font: regular) {
  font-size: 20px;
  line-height: 28px;

  @if $font == bold {
    @include fontFamily($font-kanyon-bold, 700);
  } @else {
    @include fontFamily($font-kanyon, 400);
  }
}

@mixin h3($font: regular) {
  font-size: 16px;
  line-height: 24px;

  @if $font == medium {
    @include fontFamily($font-kanyon-medium, 500);
  } @else {
    @include fontFamily($font-kanyon, 400);
  }
}

@mixin body($font: regular) {
  font-size: 14px;
  line-height: 20px;

  @if $font == semiBold {
    @include fontFamily($font-openSans-semiBold, 600);
  } @else {
    @include fontFamily($font-openSans, 400);
  }
}

@mixin small($font: regular) {
  font-size: 12px;
  line-height: 18px;

  @if $font == semiBold {
    @include fontFamily($font-openSans-semiBold, 600);
  } @else {
    @include fontFamily($font-openSans, 400);
  }
}

@mixin micro($font: regular) {
  font-size: 11px;
  line-height: 16px;

  @if $font == bold {
    @include fontFamily($font-openSans-bold, 700);
  } @else {
    @include fontFamily($font-openSans, 400);
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin centeredWithMaxWidth($maxWidth: 570px) {
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;
}
