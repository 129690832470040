@import '../abstract/colors';
@import '../abstract/mixins';

.title {
  font-family: 'Kanyon';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #{$primary-black};
}

.itemsGroup {
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
}

.itemWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.iconWrapper {
  width: 32px;
  height: 32px;
  background: #{$primary-white};
  box-shadow: 0px 12px 40px 1px rgba(0, 0, 0, 0.08);
  border-radius: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemLabel {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #{$shades-grey-75};
}