@import '../../abstract/colors';
@import '../../abstract/mixins';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  height: 100px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  padding: 16px 24px 0px 24px;
}

.wrapperHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  width: 100%;
  height: 18px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.textTitle {
  height: 18px;
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #{$shades-grey-75};
  mix-blend-mode: normal;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.inputStyle {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  font-family: $font-openSans;
  gap: 8px;
  width: 100%;
  height: 48px;
  background: white;
  border: 1px solid #{$shades-grey-10};
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  input {
    font-family: $font-openSans;
  }
}

.inputStyleError {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  font-family: $font-openSans;
  gap: 8px;
  width: 100%;
  height: 48px;
  background: white;
  border: 1px solid #{$secondary-brick-red};
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  :hover {
    border: 1px solid #{$secondary-brick-red};
  }
  :focus {
    border: 1px solid #{$secondary-brick-red};
  }
  input {
    font-family: $font-openSans;
  }
}

.slider {
  padding: 8px 30px 0px 30px;
}

.textWrapperSlider {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  padding: 0px 24px 20px 24px;
}
.right {
  height: 18px;
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #{$shades-grey-75};
  mix-blend-mode: normal;
  flex: none;
  order: 1;
  flex-grow: 1;
  text-align: right;
}
.left {
  height: 18px;
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #{$shades-grey-75};
  mix-blend-mode: normal;
  flex: none;
  order: 1;
  flex-grow: 1;
  text-align: left;
}

.errorText {
  font-size: 12px;
  color: #{$secondary-brick-red};
  display: block;
  padding-left: 24px;
  line-height: 18px;
}

.adjustText {
  font-size: 12px;
  font-family: 'OpenSansSemiBold';
  color: #{$secondary-blue};
  padding-left: 24px;
  line-height: 18px;
}

:global(.ant-input::placeholder) {
  color: #{$shades-grey-50};
}

:global(.ant-input:focus) {
  box-shadow: none !important;
  border: 1px solid #{$secondary-blue} !important;
  outline: none !important;
}

:global(.ant-input:hover) {
  box-shadow: none !important;
  border: 1px solid #{$secondary-blue} !important;
  outline: none !important;
}

.errorForm :global(.ant-slider .ant-slider-track) {
  position: absolute;
  background-color: #B4231E;
  border-radius: 2px;
  transition: background-color 0.2s;
}

.errorFormCircle :global(.ant-slider .ant-slider-handle::after) {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 10px;
  height: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 0 2px #B4231E;
  border-radius: 50%;
  cursor: pointer;
  transition: inset-inline-start 0.2s,inset-block-start 0.2s,width 0.2s,height 0.2s,box-shadow 0.2s;
}
