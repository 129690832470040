@import '../abstract/colors';
@import '../abstract/mixins';

.bgModal {
  height: 100%;
  background-color: #{$primary-black};
  opacity: 0.5;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #{$primary-white};
  border-radius: 8px;
  width: calc(100% - 16px);
  max-width: 588px;
}

@include sm {
  .content {
    width: 100%;
  }
}

.title {
  font-family: 'Kanyon';
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #13131b;
}
