@import '../abstract/colors';
@import '../abstract/mixins';

.container {
  width: 100%;
  height: 4px;
  margin: 0 auto 24px;
  position: relative;
  background-color: #{$line};
  border-radius: 2px;
}

.indicator {
  transition: width 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  height: 4px;
  position: absolute;
  left: 0;
  background-color: #{$shades-grey-10};
  border-radius: 2px;

  &.colorPercentage {
    background-color: #{$secondary-sky-blue};
  }
}
