@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins/Poppins-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'PoppinsMedium';
  src: url('./assets/fonts/Poppins/Poppins-Medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'PoppinsSemiBold';
  src: url('./assets/fonts/Poppins/Poppins-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'PoppinsBold';
  src: url('./assets/fonts/Poppins/Poppins-Bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/OpenSans/OpenSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'OpenSansSemiBold';
  src: url('./assets/fonts/OpenSans/OpenSans-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'OpenSansBold';
  src: url('./assets/fonts/OpenSans/OpenSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: 'OpenSansExtraBold';
  src: url('./assets/fonts/OpenSans/OpenSans-ExtraBold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'KanyonLight';
  src: url('./assets/fonts/Kanyon/Kanyon-Light.otf') format('opentype');
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'Kanyon';
  src: url('./assets/fonts/Kanyon/Kanyon-Regular.otf') format('opentype');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'KanyonMedium';
  src: url('./assets/fonts/Kanyon/Kanyon-Medium.otf') format('opentype');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'KanyonBold';
  src: url('./assets/fonts/Kanyon/Kanyon-Bold.otf') format('opentype');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./assets/fonts/Nunito//NunitoSans-Regular.ttf') format('opentype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'NunitoSansSemiBold';
  src: url('./assets/fonts/Nunito//NunitoSans-SemiBold.ttf') format('opentype');
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'NunitoSansBold';
  src: url('./assets/fonts/Nunito//NunitoSans-Bold.ttf') format('opentype');
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'NunitoSansExtraBold';
  src: url('./assets/fonts/Nunito//NunitoSans-ExtraBold.ttf') format('opentype');
  font-weight: 800;
  font-display: swap;
}

.grecaptcha-badge {
  display: none;
}

.swiper-button-prev {
  --swiper-pagination-color: '#fff' !important ;
}

.swiper-slide {
  height: auto !important;
}

.swiper-button-disabled {
  display: none !important;
}

.swiper-pagination-bullet {
  background-color: #246ed4 !important;
}
