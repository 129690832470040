.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(19, 19, 27, 0.5);
  display: none;
  z-index: 5;
  overscroll-behavior: contain;

  &.showOverlay {
    display: block;
  }
}
