@import '../abstract/colors';
@import '../abstract/mixins';

@mixin borderColor($type) {
  @if $type==default {
    border: 1px solid #{$shades-grey-10};
  } @else if $type==focus {
    border: 1px solid #{$secondary-blue};
  } @else if $type==error {
    border: 1px solid #{$secondary-brick-red};
  } @else {
    border: 1px solid #{$shades-grey-10};
  }
}

%inputBase {
  @include borderColor(default);
  @include body;
  padding: 12px 16px;
  width: 100%;
  color: #{$primary-black};
  border-radius: 8px;
  outline: none;
  background-color: #{$primary-white};
  height: 48px;
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
}

.titleText {
  font-size: 12px;
  color: #{$shades-grey-75};
  padding-bottom: 8px;
  display: block;
}

.inputContainer {
  position: relative;
}

.input {
  @extend %inputBase;
  opacity: 0;
  position: relative;

  &:after {
    content: '';
    padding: 0;
  }

  &:focus:after {
    color: #bf1400;
  }

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }

  &::-webkit-inner-spin-button {
    z-index: 1;
  }

  &::-webkit-clear-button {
    z-index: 1;
  }

  &::placeholder {
    color: #{$shades-grey-50};
  }

  &:focus {
    @include borderColor(focus);
  }

  &[disabled] {
    background-color: #{$shades-grey-5};
    color: #{$shades-grey-25};
    border: 1px solid #{$shades-grey-10};
  }
}

.maskInput {
  @extend %inputBase;
  position: absolute;
  display: flex;
  align-items: center;
}

.focus {
  @include borderColor(focus);
}

.error {
  @include borderColor(error);
}

.placeholder {
  color: #{$shades-grey-50};
}

.errorText {
  font-size: 12px;
  color: #{$secondary-brick-red};
  margin-top: 8px;
}

.iconCalendar {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
