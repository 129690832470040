@import '../abstract/colors';
@import '../abstract/mixins';

@mixin borderColor($type) {
  @if $type == default {
    border: 1px solid #{$shades-grey-10};
  } @else if $type == focus {
    border: 1px solid #{$secondary-blue};
  } @else if $type == error {
    border: 1px solid #{$secondary-brick-red};
  } @else {
    border: 1px solid #{$shades-grey-10};
  }
}

.wrapper {
  width: 100%;
}

.titleText {
  font-size: 12px;
  color: #{$shades-grey-75};
  margin: 8px 0px;
}

.input {
  outline: none;
  border: none;
  margin-left: 6px;
  width: 100%;
  font-family: var(--kanyon);
  font-size: 14px;
  color: #{$primary-black};
  background-color: #{$primary-white};
  &::placeholder {
    color: #{$shades-grey-50};
  }
}

.input[disabled] {
  color: #{$primary-black};
}

.default {
  @include borderColor(default);
}

.focus {
  @include borderColor(focus);
}

.error {
  @include borderColor(error);
  background-color: 10px;
}

.disabled {
  background-color: #{$shades-grey-10} !important;
  color: #{$shades-grey-75} !important;
}

.errorText {
  font-size: 12px;
  color: #{$secondary-brick-red};
  padding-top: 8px;
}

.wrapperInput {
  display: flex;
  align-items: center;
  background-color: #{$primary-white};
  padding: 12px 16px;
  border-radius: 8px;
  height: 48px;
}

.textRegion {
  font-size: 14px;
  line-height: 20px;
  padding-right: 6px;
}

.masked {
  background-color: #{$primary-white};
  padding: 12px 16px;
  border-radius: 8px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  color: #{$shades-grey-75};
  font-size: 14px;
  line-height: 20px;
}

@include lg {
  .titleText {
    font-size: 14px;
    line-height: 20px;
  }

  .input {
    font-size: 16px;
    line-height: 24px;
    margin-left: 8px;
  }

  .textRegion {
    font-size: 16px;
    line-height: 24px;
    padding-right: 8px;
  }
}
