@import '../abstract/colors';
@import '../abstract/mixins';

.container {
  background: #{$primary-white};
  border-radius: 8px;
  padding: 8px;
  flex-shrink: 0;
}

.container:first-child {
  margin-left: 16px;
}

.containerActive {
  background: #{$shades-sky-blue-5};
}

.text {
  font-family: 'Kanyon';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #{$shades-grey-75};
}

.textActive {
  font-family: 'KanyonMedium';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #{$primary-dark-blue};
}
