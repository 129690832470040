@import '../../abstract/colors';

.container {
  padding: 16px;
  background: #{$primary-white};
  box-shadow: 0px 12px 40px 1px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.description {
  font-family: 'OpenSans';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #{$shades-grey-75};
  mix-blend-mode: normal;
  margin-top: 8px;
}
