@import '../abstract/colors';
@import '../abstract/mixins';

%buttonNavigation {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;
  bottom: 50%;
  background: rgba(19, 19, 27, 0.5);
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
}

.wrapperPreview {
  background-color: #{$primary-black};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 1;
  animation: popup 0.2s;
  z-index: 6;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
}

@keyframes popup {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.swiperPreview {
  width: 100%;
  position: relative;
}

.swiperPreviewNavigation {
  width: 100%;
  padding-left: 16px !important;
  padding-right: 16px !important;
  margin-bottom: 40px;
}

.previewSubImageWrapper {
  width: auto !important;
}
.buttonIconLeft {
  padding: 16px;
  width: min-content;
  cursor: pointer;
}

.hide {
  display: block;
}

.show {
  display: block;
}

.mainImage {
  width: 100%;
  height: 283px;
  object-fit: contain;
}

.subImagePreviewActive {
  width: 86px;
  height: 64px;
  box-shadow: 0px 6px 14px 1px rgba(76, 82, 95, 0.08);
  position: relative;
  border-radius: 8px;
}

.coverImage {
  width: 86px;
  position: absolute;
  height: 64px;
  background-color: #000000;
  opacity: 0.4;
  border-radius: 8px;
}

.active {
  border: 1px solid #{$secondary-blue};
}

.subImage {
  width: 86px;
  height: 64px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 8px !important;
}

.buttonPrevPreview {
  @extend %buttonNavigation;
  margin-left: 12px;
}

.buttonNextPreview {
  @extend %buttonNavigation;
  right: 0;
  margin-right: 12px;
}
