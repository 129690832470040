@import '../abstract/colors';
@import '../abstract/mixins';

.container {
  width: 100%;
  position: relative;
}

.prefix {
  font-size: 14px;
  line-height: 20px;
  padding-right: 6px;
}

.inputArea {
  width: 100%;
  height: 48px;
  background: #{$primary-white};
  border: 1px solid #{$shades-grey-10};
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;

  &:focus-within {
    border: 1px solid #{$secondary-blue};
  }

  &.disabled {
    background: #{$shades-grey-5};
  }

  &.error {
    border: 1px solid #{$secondary-brick-red};
  }
}

.inputField {
  flex: 1;
  border: none;
  background: #{$primary-white};

  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #{$primary-black};

  &::placeholder {
    color: #{$shades-grey-50};
  }
  &:disabled {
    background: #{$shades-grey-5};
  }
}

.iconWrapper {
  &.disableClick {
    pointer-events: none;
  }
}

.dropdown {
  position: absolute;
  z-index: 80;
  background: #{$primary-white};
  margin: 8px 0 0;
  padding: 0;
  width: 100%;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.dropdownActive {
  max-height: 300px;
  overflow: auto;
  border: 1px solid #ebecee;
  border-radius: 8px;
}

.dropdownItem {
  background: #{$primary-white};
  border-bottom: 1px solid #{$shades-grey-10};
  padding: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.dropdownItemTextContainer {
    display: block;
  }

  &.disabled {
    background: #{$shades-grey-5};
    cursor: not-allowed;
  }

  &.active {
    background: #{$shades-sky-blue-5};
  }
}

.dropdownItemImage {
  width: 67.7px;
  margin-right: 14px;
}

.dropdownItemAdditionalText {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #{$shades-grey-75};
}

.dropdownItemText {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #{$primary-black};

  &.disabled {
    color: #{$shades-grey-75};
  }

  &.disableDropdownText {
    font-size: 12px;
  }
}

.dropdownNoOptionText {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #{$shades-grey-75};
}

.dropdownNoOptionItem {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  outline: none;
}
