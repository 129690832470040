@import '../abstract/colors';
@import '../abstract/mixins';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.title {
  @include small;
  color: #{$shades-grey-75};
}

.selectBoxWrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
}

.selectBox {
  background-color: #{$primary-white};
  padding: 12px 0;
  outline: 1px solid #{$shades-grey-10};
  outline-offset: -1px;
  border-radius: 8px;
  @include body;
  font-size: 16px;
  line-height: 24px;
  color: #{$primary-black};
  cursor: pointer;

  &:active,
  &:focus {
    outline: 1px solid #{$shades-grey-10};
  }

  &.selected {
    outline: 2px solid #{$secondary-sky-blue};
    outline-offset: -2px;
    background-color: #f3f9fd;
  }

  &.disabled {
    background-color: #{$shades-grey-5};
    color: #{$shades-grey-25};
    outline: 1px solid #{$shades-grey-10};
    cursor: default;
  }
}

.errorMsg {
  @include small;
  color: #{$secondary-brick-red};
}
