@import './abstract/colors';
@import './abstract/mixins';

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

body {
  color: #{$primary-black};
  font-family: var(--openSans-regular);
  font-size: 16px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

p {
  font-size: 16px;
}
@media only screen and (max-width: 1024px) {
  h1,
  .h1 {
    @include h1;
    &.bold {
      @include h1(bold);
    }
  }

  h2,
  .h2 {
    @include h2;
    &.bold {
      @include h2(bold);
    }
  }

  h3,
  .h3 {
    @include h3;
    &.medium {
      @include h3(medium);
    }
  }

  p {
    font-size: 14px;
  }
}

button {
  outline: none;
  border: none;
}

.custom-modal .ant-modal-content {
  padding: 24px 7px 18px 24px;
  height: 100%;
  max-width: 434px;
}

.custom-modal-credit .ant-modal-content {
  padding: 0px 0px 0px 0px;
  height: 100%;
  max-width: 434px;
}

.custom-modal-result-sulit .ant-modal-content {
  padding: 24px 48px 24px 24px;
  height: 108px;
}

.custom-modal-result-mudah .ant-modal-content {
  padding: 24px 48px 24px 24px;
  height: 88px;
}

.custom-modal-result .ant-modal-content {
  padding: 24px 24px 24px 24px;
  height: 488px;
}

.custom-modal .ant-modal-close {
  top: 25px;
}

.giias-modal .ant-modal .ant-modal-content {
  padding: 0 !important;
  border-radius: 10px !important;
  img {
    display: block;
  }
}
.giias-modal .ant-modal .ant-modal-close {
  top: -30px !important;
  right: -2px !important;
  color: white;
}
.custom-modal-promo .ant-modal-content {
  padding: 24px 7px 24px 24px;
  height: 100%;
  max-width: 343px;
}

.tnc-modal-ptbc .ant-modal-content {
  padding: 24px;
  height: 100%;
  max-width: 343px;
}

.city-selector-custom-modal .ant-modal-content {
  background: url('../assets/illustration/supergraphic-mobile.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  padding: 24px 15px 25px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include centeredWithMaxWidth(343px);
}

.jumpa-pay-modal .ant-modal-content {
  background: white;
  background-image: url('../assets/illustration/jumpa-pay-modal-bg.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;
  padding: 24px 15px 25px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // width modal for mobile
  max-width: 382px;
  margin-left: auto;
  margin-right: auto;
}

.toast-custom-modal .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
  width: 100%;
}

%customModal {
  position: relative;
  width: 100%;
  background: #{$primary-white};
  border-radius: 8px;
}

.share-custom-modal .ant-modal-content {
  @extend %customModal;
  padding: 40px 26px;
}

.share-custom-modal .ant-modal-body {
  width: 100%;
}

.full .ant-modal-body {
  width: 100%;
}

.full .ant-modal-content {
  @extend %customModal;
  padding: 0px;
}

.delete-account-custom-modal .ant-modal-content {
  @extend %customModal;
  padding: 48px 24px 24px;
}

.delete-account-custom-modal .ant-modal-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-account-custom-modal .ant-modal-close {
  top: 24px;
  right: 24px;
}

.csa-button {
  &.ant-float-btn {
    inset-block-end: 32px;
    inset-inline-end: 17px;
    z-index: 2; // fix issue reg-73
  }

  &.ant-float-btn-circle {
    height: 56px !important;
    width: 56px !important;
  }

  .ant-float-btn-icon {
    margin-right: 12px !important;
    margin-top: 3px !important;
  }
}

.csa-button-plp {
  @media (min-width: 570px) {
    &.ant-float-btn {
      left: calc(50% + 240px);
      transform: translateX(-50%);
      z-index: 2;
    }
  }
}

.csa-button-homepage {
  @media (min-width: 570px) {
    &.ant-float-btn {
      left: calc(50% + 240px);
      transform: translateX(-50%);
      z-index: 1000;
    }
  }
}

.whatsapp-floating-button {
  z-index: 5;
  &.ant-float-btn {
    inset-block-end: 71px;
    inset-inline-end: 16px;
    background: #{$primary-white};
    box-shadow: 0px 2px 15px 1px rgba(108, 112, 133, 0.2);
  }

  &.ant-float-btn-circle {
    height: 56px !important;
    width: 56px !important;
    z-index: 5 !important;
  }

  .ant-float-btn-content {
    width: 100%;
  }

  .ant-float-btn-icon {
    width: 100% !important;
  }

  @media (min-width: 570px) {
    &.ant-float-btn {
      left: calc(50% + 240px);
      transform: translateX(-50%);
    }
  }
}

.plp-scroll {
  .infinite-scroll-component__outerdiv {
    width: 100%;
    max-width: 343px;

    padding-top: 24px;

    .infinite-scroll-component {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
}

.price-input {
  .ant-input-affix-wrapper {
    border-color: #{$shades-grey-10} !important;

    &.ant-input-affix-wrapper-focused {
      border-color: #{$secondary-blue} !important;
    }
  }
}

.calculation-result {
  .ant-tooltip-inner {
    width: 320px;
  }
}

.multi-kk {
  .ant-tooltip-inner {
    width: 311px;
  }
}

.ant-picker-content th {
  text-align: center;
}

.adm-picker {
  height: 200px !important;
}

.adm-picker-view-column-item-label {
  height: 18px !important;
}

.adm-mask {
  right: 0;
  @include centeredWithMaxWidth;
}

.adm-popup-body-position-bottom {
  right: 0;
  @include centeredWithMaxWidth;
}

.custom-tooltip-ktp-review-domicile-field {
  top: 385px !important;

  .ant-tooltip-inner {
    width: 320px;
    padding: 8px;
  }
}

.qualification-custom-modal .ant-modal-content {
  @include centeredWithMaxWidth(343.33px);
}

.custom-modal-otp-v2 .ant-modal-content {
  padding: 40px 32px;
}

.custom-modal-otp-v2 .ant-modal-header {
  display: none;
}

.custom-modal-mask .ant-modal-content {
  margin-left: auto;
  margin-right: auto;
}

.custom-modal-mask-non-responsive .ant-modal-content {
  max-width: 570px;
}
