@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes shake-animation-X {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(8px);
  }
  75% {
    transform: translateX(-8px);
  }
  100% {
    transform: translateX(0);
  }
}

.rotateAnimation {
  animation: rotate 1s linear infinite;
}

.shake-animation-X {
  animation: shake-animation-X 0.2s ease-in-out 0s 2;
}

.shake-animation-X-2 {
  animation: shake-animation-X 0.2s ease-in-out 0s 5;
}
