@import '../../abstract/colors';

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 24px;
}

.title {
  font-family: 'OpenSans';
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #{$shades-grey-75};
}

.subtitle {
  font-family: 'OpenSansSemiBold';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #{$primary-black};
}

.subtitleBeforeDiscount {
  font-family: 'OpenSans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: #{$shades-grey-75};
  text-decoration: line-through;
}
