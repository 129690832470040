@import '../abstract/colors';
@import '../abstract/mixins';

.container {
  width: 100%;
  position: relative;
}

.inputArea {
  width: 100%;
  height: 48px;
  background: #{$primary-white};
  border: 1px solid #{$shades-grey-10};
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;

  &:focus-within {
    border: 1px solid #{$secondary-blue};
  }
}

.inputField {
  flex: 1;
  border: none;
  background: #{$primary-white};

  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #{$primary-black};

  &::placeholder {
    color: #{$shades-grey-50};
  }
}

.errorArea {
  border: 1px solid #{$secondary-brick-red};
}
