@import '../../abstract/colors';

.container {
  display: grid;
  row-gap: 12px;
  margin: 16px 0;
  align-items: center;
}

.content {
  display: flex;
  align-items: center;
}

.text {
  margin-left: 13px;
  font-family: 'OpenSans';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #{$primary-black};
}
