@import '../abstract/colors';
@import '../abstract/mixins';

.container {
  padding: 0 0 8px;
  flex-shrink: 0;
  cursor: pointer;

  &:first-child {
    margin-left: 16px;
  }

  &:last-child {
    position: relative;
    // use pseudo element because last child margin will be ignored by overflow scroll
    &::after {
      position: absolute;
      content: '';
      right: -24px;
      width: 24px;
      height: 100%;
    }
  }
}

.containerTabV2LC {
  @extend .container;
}
.containerActive {
  border-bottom: 2px solid #{$secondary-blue};
}
.tabBrand {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5.2px;
  font-family: inherit;
}

.text {
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #{$shades-grey-75};
}

.textActive {
  font-family: $font-openSans-semiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #{$primary-dark-blue};
}
