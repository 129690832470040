:root {
}

[data-rsbs-overlay] {
  z-index: 99;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  max-width: 570px;
  margin: 0 auto;
}

[data-rsbs-header] {
  padding: 0;
}

[data-rsbs-has-header='false'] [data-rsbs-header] {
  padding-top: 24px;
}

[data-rsbs-header]:before {
  height: 0;
}

[data-rsbs-backdrop] {
  z-index: 98;
}
